<template>
  <div class="upx">
    <div class="upx--bg"></div>
    <div class="upx--overlay"></div>
    <div class="upx--modal">
      <div class="upx--modal-close">
        <img src="@/assets/img/icon-cross.svg" alt="close">
      </div>
      <div class="upx--modal-header">
        <h1 class="upx--modal-title">Регистрация</h1>
      </div>
      <div class="upx--modal-tabs">
        <div
          v-for="{name, icon, text} in tabs"
          :key="name"
          class="upx--modal-tab"
          :class="{ 'active': name === selectedTab }"
          @click="selectedTab = name"
        >
          <div class="upx--modal-tab-icon">
            <img
              :src="require(`@/assets/img/${icon}`)"
              alt="icon"
            >
          </div>
          <div class="upx--modal-tab-text">
            {{ text }}
          </div>
        </div>
      </div>
      <div class="upx--modal-content">
        <form
          action="#"
          id="registrationForm"
          class="form"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div
            v-if="selectedTab === 'phone'"
            class="form-row"
          >
            <div class="form--group form--group_code">
              <select
                id="telCode"
                name="telCode"
                class="form--input form--select"
                v-model="telCode"
              >
                <option
                  v-for="{code} in phoneCodes"
                  :key="code"
                  :value="code"
                >
                  {{ code }}
                </option>
              </select>
              <div class="form--input-icon">
                <img
                  src="@/assets/img/icon-chevron-down.svg"
                  alt="icon"
                >
              </div>
              <div class="form--select-icon">
                <img
                  :src="require(`@/assets/img/flags/${country}.webp`)"
                  :alt="`flag-${country}`"
                >
              </div>
            </div>
            <div class="form--group form--group_phone">
              <input
                type="tel"
                id="phone"
                name="phone"
                class="form--input"
                v-model="phone"
                required
              />
              <label
                for="phone"
                class="form--label"
              >
                Номер телефона
              </label>
            </div>
          </div>
          <div
            v-if="selectedTab === 'email'"
            class="form--group"
          >
            <input
              type="email"
              id="email"
              class="form--input"
              v-model="email"
              required
            />
            <label
              for="email"
              class="form--label"
            >
              E-mail
            </label>
          </div>
          <div class="form--group">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              class="form--input"
              v-model="password"
              required
            />
            <label
              for="password"
              class="form--label"
            >
              Пароль
            </label>
            <div class="form--input-icon">
              <img
                src="@/assets/img/icon-eye.svg"
                alt="icon"
                @click="showPassword = !showPassword"
              >
            </div>
          </div>
          <div class="form--group">
            <select
              id="country"
              class="form--input form--select"
              v-model="country"
            >
              <option
                v-for="{name, code} in countries"
                :key="code"
                :value="code"
              >
                {{ name }}
              </option>
            </select>
            <label
              for="country"
              class="form--label"
            >
              Страна
            </label>
            <div class="form--input-icon">
              <img
                src="@/assets/img/icon-chevron-down.svg"
                alt="icon"
              >
            </div>
            <div class="form--select-icon">
              <img
                :src="require(`@/assets/img/flags/${country}.webp`)"
                :alt="`flag-${country}`"
              >
            </div>
          </div>
          <div class="form--group">
            <select
              id="currency"
              class="form--input form--select"
              v-model="currency"
            >
              <option
                v-for="{name, code} in currencies"
                :key="code"
                :value="code"
              >
                {{ name }}
              </option>
            </select>
            <label
              for="country"
              class="form--label"
            >
              Валюта
            </label>
            <label for="country" class="form--input-icon">
              <img
                src="@/assets/img/icon-chevron-down.svg"
                alt="icon"
              >
            </label>
            <div class="form--select-icon">
              <img
                :src="require(`@/assets/img/currencies/${currency}.webp`)"
                :alt="`currency-${currency}`"
              >
            </div>
          </div>
          <input
            type="submit"
            class="form--submit"
            :class="{'form--submit_disabled': submitFormDisabled}"
            id="register"
            value="Регистрация"
          />
        </form>
      </div>
      <div class="upx--modal-footer">
        Авторизуясь на сайте Вы принимаете условия <a href="#">пользовательского соглашения</a> и <a href="#">политики конфиденциальности</a>, обязуетесь соблюдать их в течение всего периода нахождения на сайте и подтверждаете, что уже достигли возраста 18 лет и более.
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

const TABS = [
  {
    name: 'email',
    icon: 'Icon-email.svg',
    text: 'E-mail'
  },
  {
    name: 'phone',
    icon: 'Icon-phone.svg',
    text: 'Телефон'
  }
]

const COUNTRIES = [
  {
    name: 'Российская Федерация',
    code: 'RU'
  },
  {
    name: 'Азербайджан',
    code: 'AZ'
  },
  {
    name: 'Казахстан',
    code: 'KZ'
  },
  {
    name: 'Украина',
    code: 'UA'
  }
]

const CURRENCIES = [
  {
    name: 'Российский рубль',
    code: 'RUB'
  },
  {
    name: 'Азербайджанский манат',
    code: 'AZN'
  },
  {
    name: 'Казахстанский тенге',
    code: 'KZT'
  },
  {
    name: 'Украинская гривна',
    code: 'UAH'
  }
]

const PHONE_CODES = [
  {
    country: 'RU',
    code: '+7',
    name: 'Российская Федерация'
  },
  {
    country: 'AZ',
    code: '+994',
    name: 'Азербайджан'
  },
  {
    country: 'KZ',
    code: '+7',
    name: 'Казахстан'
  },
  {
    country: 'UA',
    code: '+380',
    name: 'Украина'
  }
]

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      email: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      checkbox: false,
      isRequestSending: false,
      selectedTab: 'email',
      showPassword: false
    }
  },
  computed: {
    submitFormDisabled () {
      return !this.password || (this.selectedTab === 'email' && !this.email) || (this.selectedTab === 'phone' && !this.phone)
    }
  },
  created () {
    this.tabs = TABS
    this.countries = COUNTRIES
    this.currencies = CURRENCIES
    this.phoneCodes = PHONE_CODES
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: '',
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      if (this.selectedTab === 'email') {
        data.user.email = this.email
      } else {
        data.user.phone = {
          country: this.country,
          number: `${this.telCode}${this.phone}`
        }
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
